import styled from "@emotion/styled";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ArtworkPage from "./Pages/ArtworkPage";
import ArtworksPage from "./Pages/ArtworksPage";
import LandingPage from "./Pages/LandingPage";
import TimetablePage from "./Pages/TimetablePage";
import VisualsPage from "./Pages/VisualsPage";

const Container = styled.main``;

function App() {
  return (
    <Container>

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/timetable" element={<TimetablePage />} />
          <Route path="/visuals" element={<VisualsPage />} />
          <Route path="/artwork/:slug" element={<ArtworkPage />} />
          <Route path="/artwork" element={<ArtworksPage />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}

export default App;
