import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/2022/SectionIntroductionBackground.png";
import dustcloud from "../../../images/2022/SectionIntroductionDustCloud.svg";
import header from "../../../images/2022/SectionIntroductionHeader.svg";
import header2 from "../../../images/2022/SectionIntroductionHeader2.svg";
import header3 from "../../../images/2022/SectionIntroductionHeader3.svg";
import squidimage from "../../../images/2022/SectionIntroductionSquidImage.png";
import NarrowSection from "../../Section/NarrowSection";
import Section from "./../../Section/Section";
import ArtistList from "./ArtistList";

const Container = styled.div`
  padding-top: 3rem;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  
    font-size: 1.5rem;
    line-height: 1.5rem;

  img {
    width: 100%;
  }
  .dustcloud {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const Introduction = () => {
  return (
    <Container>
      <Section>
        <img src={header} alt="Collisions" />
      </Section>
      <NarrowSection>
        <img src={header2} alt="exhibition" />
        <br />
        <br />
        <ArtistList edition="muc_exhibition" />
        <br />
        <br />
        <img src={header3} alt="festival" />
        <br />
        <br />
        <ArtistList edition="muc_festival" />
        <br />
        <br />
      </NarrowSection>
      <Section>
        <img src={squidimage} alt="squid" />
      </Section>
      <img src={dustcloud} className="dustcloud" alt="Dust cloud" />
    </Container>
  );
};

export default Introduction;
