import { mountStoreDevtool } from "simple-zustand-devtools";
import create from "zustand";
import { artists } from "../Mocks/Artists";

declare global {
  interface Window { isPlaying: boolean; }
}

window.isPlaying  = true;

export type Artist = {
  name: string;
  identification: string;
  description: string;
  link: string;
  image: string;
  editions: EditionReference[]
};

export type EditionReference = 'canfranc' | 'muc_festival' | 'muc_exhibition';


export const useLayoutStore = create<State>((set) => ({
  primaryColor: "var(--primary)",
  secondaryColor: "var(--secondary)",
  artists: artists,
  setArtists: (artists) => set({ artists: artists }),
  isPlaying: window.isPlaying,
  setIsPlaying: (isPlaying) => { 
    set({ isPlaying: isPlaying })
    window.isPlaying = isPlaying; 
  },
  delay: 20000,
  setDelay: (delay) => set({ delay: delay }),
}));

type State = {
  primaryColor: string;
  secondaryColor: string;
  artists: Artist[];
  setArtists: (artists: Artist[]) => void;
  isPlaying: boolean;
  setIsPlaying: (isPlaying: boolean) => void;
  delay: number;
  setDelay: (delay: number) => void;
};

if (process.env.REACT_APP_STAGE === undefined) {
  let root = document.createElement("div");
  root.id = "layoutStore";
  document.body.appendChild(root);
  mountStoreDevtool("LayoutStore", useLayoutStore as any, root);
}
