import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/2022/SectionCreditsBackground.png";
import logos from "../../../images/2022/SectionCreditsLogos.svg";
import Section from "../../Section/Section";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1.25rem;
  line-height: 1.25rem;
  img {
    max-width: 100%;
  }
  section {
    padding: 2rem 0;
  }
`;

const Credits = () => {
  return (
    <Container>
      <Section>
        <p>
        SVS COLLISIONS Exhibition wird von SVS Event &amp; Curation UG (haftungsbeschränkt) produziert, vom Muffatwerk koproduziert und gefördert vom Musikfonds e. V. mit Projektmitteln der Beauftragten der Bundesregierung für Kultur und Medien im Rahmen des Sonderprogramms Neustart Kultur und dem Kulturreferat der Stadt München.<br />
          Das COLLISIONS Festival wird von Beni Brachtel, dem SVS Kollektiv und Dietmar Lupfer kuratiert, vom Muffatwerk produziert und gefördert von Neustart Kultur, der Initiative Musik und der Bundesregierung für Kultur und Medien (BKM).<br />
          project management: SVS Event &amp; Curation UG, Kulturbüro Todorow Sacher<br />
          press &amp; public relations by STAY Agency, Kulturbüro Todorow Sacher<br />
          <br />
          VWEXD Team: Mandy Mozart, Lena Thomaka &amp; Tatjana Stürmer
        </p>
        <p>
          Collisions21 Canfranc Residency wurde unterstützt durch das<br />
          Goetheinstitut und das Kulturreferat der Landeshauptstadt München.
        </p>
        <img src={logos} alt="logos"/>
        <p>
          Impressum
          <br />
          <br />
          Angaben gemäß § 5 TMG
          <br />
          <br />
          SVS Event &amp; Curation UG (haftungsbeschränkt)
          <br />
          Zielstattstr. 37
          <br />
          Rgb
          <br />
          81379 München
          <br />
          <br />
          <br />
          Handelsregister: HRB 256357
          <br />
          Registergericht: Amtsgericht München
          <br />
          <br />
          <br />
          Vertreten durch:
          <br />
          Benedikt Brachtel
          <br />
          <br />
          <br />
          Kontakt
          <br />
          <br />
          Telefon: +49 (0) 1778901301
          <br />
          E-Mail: contact@svs-records.com
          <br />
          <br />
          <br />
          Verbraucher­streit­beilegung/Universal­schlichtungs­stelle
          <br />
          <br />
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </Section>
    </Container>
  );
};

export default Credits;
