import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
// import hairdrop from "../../../images/2022/SectionLongHairDrop.svg";
// import whirlwind from "../../../images/2022/SectionSuperWhirlWind.svg";
import avatarPlaceholder from "../../../images/avatarPlaceholder.png";
import fillInShape1 from "../../../images/FillInShape1.png";
import fillInShape2 from "../../../images/FillInShape2.png";
import fillInShape3 from "../../../images/FillInShape3.png";
import fillInShape4 from "../../../images/FillInShape4.png";
import { EditionReference, useLayoutStore } from "../../../Stores/LayoutStore";
import NarrowSection from "../../Section/NarrowSection";
import Section from "../../Section/Section";

const Container = styled.div`
  section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    @media ${device.mdDown} {
      display: block;
    }
    div {
       img {
        width: 100%;
        position: sticky;
        top: 3rem;
      }
    }

    h3,
    h4 {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.25rem;
      font-weight: bold;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.25rem;
    }
  }
  .whirlwind {
    position: sticky;
    bottom: 4rem;
    right: 4rem;
  }
  .hairdrop {
    position: sticky;
    top: 4rem;
    text-align: left;
  }
  @media (prefers-reduced-motion: no-preference) {
    .rotate {
      animation: rotatingShape-spin infinite 20s linear;
    }
  }

  @keyframes rotatingShape-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const StyledAvatar = styled.div`
  position: sticky;
  position: sticky;
  top: calc(100vh - 10rem);
  width: 100%;
  img {
    width: 3rem;
    height: 3rem;
  }
`;

const Avatar = () => {
  return (
    <StyledAvatar>
      <img src={avatarPlaceholder} alt="Blutwurst!" />
    </StyledAvatar>
  );
};

type Props = {
  edition: EditionReference;
};

const ArtistDetailList = ({ edition }: Props) => {
  const artists = useLayoutStore((store) => store.artists);
  let index = 0;
  return (
    <Container className={`artist-detail-list artist-detail-list--${edition}`}>
      {edition === "canfranc" && <Avatar />}
      {edition === "canfranc" && (
        <NarrowSection>
          {artists
            .filter((artist) => artist.editions.find((e) => e === edition))
            .map((artist) => {
              index++;
              return (
                <div key={`artist-${index}`}>
                  {index === 3 && (
                    <div>
                      <img src={fillInShape1} alt="Fill in particle" />
                    </div>
                  )}
                  {index === 9 && (
                    <div>
                      <img src={fillInShape2} alt="Fill in particle" />
                    </div>
                  )}
                  {index === 16 && (
                    <div>
                      <img src={fillInShape3} alt="Fill in particle" />
                    </div>
                  )}
                  {index === 23 && (
                    <div>
                      <img src={fillInShape4} alt="Fill in particle" />
                    </div>
                  )}
                  <div key={artist.name}>
                    <a href={artist.link} target="_blank" rel="noreferrer">
                      <h3>{artist.name}</h3>
                      <h4>({artist.identification})</h4>
                      <p>{artist.description}</p>
                    </a>
                  </div>
                </div>
              );
            })}
        </NarrowSection>
      )}
      {edition !== "canfranc" && (
        <Section>
          {artists
            .filter((artist) => artist.editions.find((e) => e === edition))
            .map((artist) => {
              index++;
              return (
                <div key={`artist-${index}`}>
                  <div key={artist.name}>
                    <a href={artist.link} target="_blank" rel="noreferrer">
                      <h3>{artist.name}</h3>
                      <p>{artist.description}</p>
                    </a>
                  </div>
                </div>
              );
            })}
      {/* <img src={hairdrop} className="hairdrop" alt="hairdrop" />
   <img src={whirlwind} className="whirlwind rotate" alt="whirlwind" /> */}
        </Section>
      )}
    </Container>
  );
};

export default ArtistDetailList;
