import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/BackgroundIntroduction.png";
import logo from "../../../images/logo.svg";
import NarrowSection from "../../Section/NarrowSection";
import antiShape from "./../../../images/antiShape.svg";
import Section from "./../../Section/Section";

const Container = styled.div`
  padding-top: 3rem;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  section:nth-of-type(1) {
    text-align: left;
    img {
      margin-left: 15rem;
      @media ${device.xsDown} {
        margin-left: 8rem;
        height: 7rem;
      }
    }
  }
  section:nth-of-type(2) {
    height: calc(100vh - 17rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      display: block;
      position: sticky;
      top: 6rem;
      @media ${device.lgDown} {
        top: 18rem;
      }
      @media ${device.mdDown} {
        top: 10rem;
      }
    }
    h1 {
      font-size: 2.25em;
      line-height: 2.25rem;
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 17rem;
      @media ${device.lgDown} {
        width: var(--inner-width-narrow);
      }
      @media ${device.mdDown} {
        width: 100%;
      }
    }
  }
`;

const Introduction = () => {
  return (
    <Container>
      <Section>
        <img src={antiShape} alt="anti shape" />
      </Section>
      <NarrowSection>
        <img src={logo} alt="Collisions" />
        <h1>
          Mixed Media Arts Residency
          <br />
          July 24 to August 8
        </h1>
      </NarrowSection>
    </Container>
  );
};

export default Introduction;
