import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { device } from "../breakpoints";

// import { artworks } from "../Mocks/Artworks";

const Container = styled.div`
  padding: 4rem;
  background-color: #fff;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: #000;
  min-height: calc(100vh - 4rem);
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    margin: 3rem 0;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  p,
  a {
    color: #000;
  }
`;

const ArtworksPage = () => {
  const [artworks, setArtworks] = useState();
  useEffect(() => {
  if(!artworks)
      fetch(
        `https://api.sheety.co/6f92f2531f272b85130005f9d671fb6e/collisions/artworks`
      )
        .then((response) => response.json())
        .then((data) => {
          setArtworks(data.artworks)
          console.log(data)
        });
  }, [artworks, setArtworks]);
  if(!artworks) return <>Loading ...</>
  return (
    <Container>
      {artworks?.map((artwork) => (
        <>
          <Link to={`${artwork.slug}`}>
            {artwork.artist} — {artwork.title}
            <br />
            <br /><br /><br />
            <QRCode
              value={`https://www.collisions.cc/artwork/${artwork.slug}`}
            />
            <br /><br /><br />
          </Link>
          <br />
        </>
      ))}
    </Container>
  );
};

export default ArtworksPage;
