import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/2022/SectionLocationsBackground.png";
import NarrowSection from "../../Section/NarrowSection";
import Newsletter from "./Newsletter";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: bold;
  color: var(--festival-primary);
  @media ${device.mdDown} {
    font-size: 2rem;
    line-height: 2rem;
  }
  padding: 33rem 0 43rem 0;
  section {
    h2 {
      margin: 3rem 0;
      font-size: 3rem;
      line-height: 3rem;
      top: 2rem;
      color: var(--festival-primary);
      @media ${device.mdDown} {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
`;

const Locations = () => {
  return (
    <Container>
      <NarrowSection>
        <h2>Location</h2>
        <br />
        <a href="https://goo.gl/maps/KXiFR2nWsVYYpDEm6"   rel="noreferrer"target="_blank">
          Muffatwerk<br />
          Zellstraße 4<br />
          81667 München
        </a>
        <br /><br /><br /><br /> <br /><br /><br /><br /> <br /><br /><br /><br /> <br /><br /><br /><br /> <br /><br /><br /><br />
        <Newsletter/>
      </NarrowSection>
    </Container>
  );
};

export default Locations;
