import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/2022/SectionInterludeBackground.png";
import flowershape from "../../../images/2022/SectionInterludeFlowerShape.svg";
import shrimp from "../../../images/2022/SectionInterludeShrimp.png";
import truehorsi from "../../../images/2022/SectionInterludeTrueHorsi.svg";
import NarrowSection from "../../Section/NarrowSection";
import Section from "../../Section/Section";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 10rem 0; */
  position: relative;
  .flowershape {
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
  }
  .shrimp {
    background-color: black;
  }
  img {
    width: 100%;
  }
`;

const Interlude = () => {
  return (
    <Container>
      <Section>
        <img src={truehorsi} className="truehorsi" alt="true horsi" />
      </Section>
      <NarrowSection>
        <img src={shrimp} className="shrimp" alt="shrimp" />
      </NarrowSection>
      <Section>
        <img src={flowershape} className="flowershape" alt="true horsi" />
      </Section>
    </Container>
  );
};

export default Interlude;
