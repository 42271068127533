import styled from "@emotion/styled";
import { device } from "../breakpoints";
import HomepageLink from "../Components/Overlays/HomepageLink";
import Tickets from "../Components/Overlays/Tickets";
import Timetable from "../Components/Sections/2022/Timetable";
import backgroundImage from "../images/2022/SectionTimetableBackground.png";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: var(--festival-primary);
  img {
    width: 100%;
  }
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    margin: 3rem 0;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--festival-primary);
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
`;


// const Menu = styled.nav`
//   & {
//     position: sticky;
//     top: 6rem;
    
//     font-size: 2rem;
//     z-index: 1;
//     line-height: 2rem;
//     padding: 0.5rem;
//     background-color: var(--festival-secondary);
//     border-radius: 1rem 1rem 0 0;
//     border-top: 2px solid var(--festival-primary);
//     border-left: 2px solid var(--festival-primary);
//     border-right: 2px solid var(--festival-primary);
//   }
//   a {
//     color: var(--festival-primary);
//   }
//   .active {
//     text-decoration: underline;
//   }
// `;
// const MenuEnd = styled.div`
//   & {

//     font-size: 2rem;
//     line-height: 2rem;
//     padding: 0.5rem;
//     color: var(--festival-primary);
//     border-radius: 0 0 1rem 1rem;
//     border-bottom: 2px solid var(--festival-primary);
//     border-left: 2px solid var(--festival-primary);
//     border-right: 2px solid var(--festival-primary);
//   }
// `;
// const MenuItem = ({ isActive, children, href, props }) => {
//   return (
//     <a className={clsx({ active: isActive })} href={href} {...props}>
//       {children}
//     </a>
//   );
// };

const TimetablePage = () => {
  // const [activeDay, setActiveDay] = useState();
  return (
    <Container>
      <Tickets/>
      <HomepageLink />
      <Timetable/>
    </Container>
  );
};

export default TimetablePage;
