import styled from "@emotion/styled";
import { useEffect } from "react";
import bg8 from "../images/2022/SectionCreditsBackground.png";
import bg2 from "../images/2022/SectionDescriptionBackground.png";
import bg5 from "../images/2022/SectionExhibitionArtistsBackground.png";
import bg6 from "../images/2022/SectionFestivalArtistsBackground.png";
import bg3 from "../images/2022/SectionInterludeBackground.png";
import bg1 from "../images/2022/SectionIntroductionBackground.png";
import bg7 from "../images/2022/SectionLocationsBackground.png";
import bg9 from "../images/2022/SectionOutroBackground.png";
import bg4 from "../images/2022/SectionTimetableBackground.png";

import logos from "../images/2022/SectionCreditsLogos.svg";
import truehorsi from "../images/2022/SectionInterludeTrueHorsi.svg";
import dustcloud from "../images/2022/SectionIntroductionDustCloud.svg";
import header from "../images/2022/SectionIntroductionHeader.svg";
import header2 from "../images/2022/SectionIntroductionHeader2.svg";
import header3 from "../images/2022/SectionIntroductionHeader3.svg";
import squid from "../images/2022/SectionIntroductionSquidImage.png";
import longhairdrop from "../images/2022/SectionLongHairDrop.svg";
import spring from "../images/2022/SectionOutroSpring.svg";
import whirlwind from "../images/2022/SectionSuperWhirlWind.svg";
import rushmore from "../images/2022/SectionTimetableMountRushmore.svg";


const Container = styled.div`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: normal;
  color: var(--festival-primary);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .text {
    font-size: 3rem;
    line-height: 3rem;
    color: var(--festival-primary);
    span, div {
      color: var(--festival-primary) !important;
    }
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  img {
    width: 100%;
    display: block;
  }
  .backgrounds {
    width: 100vw;
    transform: translateY(0);
    animation: scroll 5600s infinite linear;
  }
  .vectors {
    width: 100vw;
    transform: translateY(0);
    position: fixed;
    top: 0;
    left: 0;
    animation: scrollReverse 7000s infinite linear;
    .whirlwind {
      animation: rotation 10s infinite linear;
      transform: scale(.25);
    }
  }
  @keyframes scroll {
    0% {
      transform: scale(1) translateY(0);
    }
    50% {
      transform: scale(1) translateY(-100%);
    }
    100% {
      transform: scale(1) translateY(0);
    }
  }
  @keyframes scrollReverse {
    0% {
      transform: scale(1) translateY(-100%);
    }
    50% {
      transform: scale(1) translateY(0);
    }
    100% {
      transform: scale(1) translateY(-100%);
    }
  }


  @media (prefers-reduced-motion: no-preference) {
    .rotate {
      animation: rotation infinite 20s linear;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const VisualsPage = () => {
  useEffect(() => {
    window.festivalVisibility = true;
  }, []);
  return (
    <Container>
      <div className="backgrounds">
        <img src={bg1} alt="bg" />
        <img src={bg2} alt="bg" />
        <img src={bg3} alt="bg" />
        <img src={bg4} alt="bg" />
        <img src={bg5} alt="bg" />
        <img src={bg6} alt="bg" />
        <img src={bg7} alt="bg" />
        <img src={bg9} alt="bg" />
        <img src={bg8} alt="bg" />
      </div>
      <div className="vectors">
      <img src={logos} alt="vector" />
      <img src={truehorsi} alt="vector" />
      <img src={dustcloud} alt="vector" />
      <img src={header} alt="vector" />
      <img src={header2} alt="vector" />
      <img src={header3} alt="vector" />
      <img src={squid} alt="vector" />
      <img src={longhairdrop} alt="vector" />
      <img src={spring} alt="vector" />
      <img src={whirlwind} alt="vector" className="whirlwind" />
      <img src={rushmore} alt="vector" />

      </div>
      <div className="text" spellcheck={false} contentEditable={true}>
        Text
      </div>
    </Container>
  );
};

export default VisualsPage;
