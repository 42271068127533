import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { device } from "../breakpoints";

const Container = styled.div`
  padding: 2rem;
  background-color: #000;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: normal;
  color: #fff;
  min-height: calc(100vh - 4rem);
  img {
    width: 100%;
  }
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    margin: 3rem 0;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  p,
  a {
    color: #fff;
  }
`;

const ArtworkPage = () => {
  const { slug } = useParams();
  const [artwork, setArtwork] = useState();
  useEffect(() => {
  if(!artwork)
      fetch(
        `https://api.sheety.co/6f92f2531f272b85130005f9d671fb6e/collisions/artworks`
      )
        .then((response) => response.json())
        .then((data) => {
          const artwork = data.artworks.find((a) => a.slug === slug);
          setArtwork(artwork)
        });
  }, [artwork, setArtwork, slug]);
  if(!artwork) return <>Loading ...</>
  
  // const opts = {
  //   height: "480",
  //   width: "640",
  //   playerVars: {
  //     // https://developers.google.com/youtube/player_parameters
  //     autoplay: 1,
  //   },
  // };
  console.log(artwork);
  if (artwork === undefined)
    return (
      <Container>
        404 — This artwork was never created in and before the end of times.
      </Container>
    );
  return (
    <Container>
      <h1>{artwork.artist} — {artwork.title}</h1>
      <ReactMarkdown>{artwork.description}</ReactMarkdown>
      {artwork.link && (
        <a href={artwork.link} target="_blank" rel="noreferrer">
          ➝ External Link
        </a>
      )}
      <br />
      {artwork.slug === "mandymozart-live" && (
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/VlVm1nmZwv8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      )}
    </Container>
  );
};

export default ArtworkPage;
