import Tickets from "../Components/Overlays/Tickets";
import Residency from "../Components/Sections/2021/Residency";
import Festival from "../Components/Sections/2022/Festival";
import LayoutLogic from "../LayoutLogic";

const LandingPage = () => {
  return (
    <>
    <Tickets/>
      <LayoutLogic />
      <Festival />
      <Residency />
    </>
  );
};
export default LandingPage;
