import styled from "@emotion/styled";
import React, { FC, ReactNode } from "react";
import { device } from "../../breakpoints";

const Container = styled.section`
  /* min-height: 57rem; */
  width: var(--inner-width-narrow);
  margin: 0 auto;
  text-align: center;
  @media ${device.mdDown} {
    width: calc(100% - 2rem);
    padding: 0 1rem;
  }
`;

type Props = {
  children: ReactNode | ReactNode[];
};

const NarrowSection: FC<Props> = ({children }) => {
  return <Container>{children}</Container>;
};

export default NarrowSection;
