import styled from "@emotion/styled";
import React from "react";
import handleViewport from "react-in-viewport";
import backgroundImage from "../../../images/BackgroundArtistDetailList.png";
import Header from "../../Header/Header";
// import Sticker from "./../../../Components/Overlays/Sticker";
import ArtistDetailList from "./ArtistDetailList";
import ArtistList from "./ArtistList";
import Description from "./Description";
import Explosion from "./Explosion";
import Introduction from "./Introduction";
import Newsletter from "./Newsletter";
import Supporters from "./Supporters";

const Block = (props) => {
  const { forwardedRef, children } = props;
  return (
    <div className="viewport-block" ref={forwardedRef}>
      {children}
    </div>
  );
};

const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);

const Container = styled.main`
  margin-top: -100rem;
  background-color: var(--secondary);
  .artist-detail-list--canfranc {
    background-image: url(${backgroundImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

function Residency() {
  return (
    <Container>
      <ViewportBlock
        onEnterViewport={() => (window.residencyVisibility = true)}
        onLeaveViewport={() => (window.residencyVisibility = false)}
      >
        {/* <Sticker /> */}
        <Header />
        <Introduction />
        <ArtistList />
        <Description />
        <Explosion />
        <ArtistDetailList edition="canfranc" />
        <Newsletter />
        <Supporters />
      </ViewportBlock>
    </Container>
  );
}

export default Residency;
