import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/2022/SectionDescriptionBackground.png";
import NarrowSection from "../../Section/NarrowSection";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  section {
    h3 {
      color: var(--festival-primary);
      font-size: 2rem;
      line-height: 2rem;
      font-weight: normal;
    }
    > p {
      color: var(--festival-primary);
      font-size: 2rem;
      line-height: 2rem;
    }
  }
`;

const Description = () => {
  return (
    <Container>
      <NarrowSection>
        <h3>SVS COLLISIONS</h3>
        <p>
          Being in two different places at the same time – sounds more like a
          pipe dream than reality. Yet scientists have been dealing with this
          phenomenon for decades: What actually is reality when something can be
          there and not there at the same time? Does invisible dark matter lie
          behind this cosmological conundrum? What certainly strikes us is that
          particle physics employs a vocabulary remarkably similar to sound:
          frequencies, waves, noise, transients, silence.
        </p>
        <p>
          The SVS collective started a dialogue with scientists to express
          common themes through a common language: Is music subjected to
          chronology? Can temporal rigour be overcome? How do particles sound
          when they collide? In summer 2021, SVS invited sound artists to
          collaborate with physicists at the Laboratorio subterraneo de
          Canfranc, one of the leading research laboratories for dark matter in
          the Pyrenees, Spain, and established an environment for
          interdisciplinary experimentation. From the process of mutual
          exchange, various artistic formats emerged – from mixed media works to
          performances – that mediate between and link both worlds.
        </p>
        <h3>EXHIBITION + FESTIVAL</h3>
        <p>
          From 19 to 21, 23+24 July 2022, works created during the residency in
          Canfranc will be presented as part of the mixed-media festival
          COLLISIONS. In addition, from 19 to 21 July there will be an extensive
          music programme with concerts, live performances and DJ sets by
          international guest artists and the participants of the residency.
        </p>
        <h3>MONOM / 4DSOUND at STUDIO 1</h3>
        <p>
          MONOM is a studio exploring the potential of spatial sound as an
          expressive medium using 4DSOUND technology, an advanced multichannel
          sound environment and instrument which enables the production of audio
          holograms.
        </p>

        <h3>ABOUT SVS</h3>
        <p>
          Initiated by composer Benedikt Brachtel and music producer Daniel
          Hermann-Collini, SVS Creative Labs, the residencies for sound-related
          art, have been taking place in the most remote places in Europe. The
          new series COLLISIONS was created to expand the various artistic
          perspectives beyond the boundaries of the self-referential.
        </p>
      </NarrowSection>
    </Container>
  );
};

export default Description;
