import { Artist } from "../Stores/LayoutStore";

export const artists: Artist[] = [
  {
    name: "Maria Margolina / Slovva",
    identification: "Media Artist, Musician",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,e_grayscale,h_96,w_96/v1627936950/Photos/Maria_Margolina_rerk73.jpg",
    description:
      "Maria Margolina is a media artist, musician and DJ. She works with various media such as sound, video and coding. From 2018 to 2021, she was part of DAF - Dynamic Acoustic Research, an open sound and performance collective. She has also joined the community online radio Radio 80000 in 2020, where she has a regular show as 'Slovva'. Maria Margolina is currently studying sculpture and design in three-dimensional space in the class of Olaf Nicolai at the Academy of Fine Arts in Munich.",
    link: "https://www.instagram.com/ssslovva/",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Judith Neunhäuserer",
    identification: "Visual Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936916/Photos/Instagram%20B_W/judith_oasitz.jpg",
    description:
      "Judith Neunhäuserer's work deals with demarcation and aesthetic and epistemic commonalities between science and religion. It is about myths and dreams as the basis for discoveries, the ritualisation of research processes and different languages and symbol systems. Her expeditions have taken her across the Atlantic, to the Arctic Circle and the Neumayer Station III in Antarctica, among other places. Judith Neunhäuserer studied sculpture and religious and cultural studies in Munich and Istanbul. She currently lives and works in Munich and Milan.",
    link: "https://judithneunhaeuserer.info/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Kalas Liebfried",
    identification: "Audiovisual and Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936908/Photos/Instagram%20B_W/6_jegh1d.jpg",
    description:
      'Kalas Liebfried studied sculpture and time-based media with Stephan Huber, Julian Rosefeldt and Alexandra Bircken at the Academy of Fine Arts Munich and philosophy at the LMU Munich. Central to his work is the exploration of the sculptural and socio-political potentials of sound. He is curator of the independent art space "Rosa Stern Space" and co-founder of the non-label organisation "PARA". Solo presentations at Goethe-Institut Paris and Sofia (2021), Pinakothek der Moderne (2019), among others.',
    link: "https://kalasliebfried.com/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Jakob Braito",
    identification: "Visual and Sound Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936905/Photos/Instagram%20B_W/jakob_nzjbud.jpg",
    description:
      "Jakob Braito studies sculpture and three-dimensional space with Olaf Nicolai at the Academy of Fine Arts Munich. He works at the intersection of sculpture and sound and understands sound as a space-creating medium. Jakob Braito has been co-running the Munich community radio station Radio 80000 since 2016 and co-initiated the non-label project PARA last year.",
    link: "https://braito.xyz/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Beni Brachtel",
    identification: "Composer, Music Producer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936991/Photos/Instagram%20B_W/15_ieqcr0.jpg",
    description:
      'Beni Brachtel alias Bartellow works as a composer, music producer and label manager. Since 2012, he has written over twenty compositions for theatre and received four commissions from the Bavarian State Opera. His music is featured on over fifty recordings, and he has toured internationally as a solo artist and musician. In 2007, Beni Brachtel launched the SVS residency programme and the associated music imprint.',
    link: "https://www.benibrachtel.com",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "BELP",
    identification: "Producer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936919/Photos/Instagram%20B_W/3_wpqbjx.jpg",
    description:
      "Sebastian Schnitzenbaumer is a pianist and producer of electronic bass music. As the owner of the label Schamoni / Jahmoni Musik, he has been promoting and shaping the Munich music scene for many years. Sebastian has monthly radio shows on Radio Lora, Radio 80000 and Internet Public Radio Guadalajara and is a co-initiator of Monokultur München.",
    link: "https://belp.audio/",
    editions: ["canfranc"],
  },
  {
    name: "Daniel Door",
    identification: "Audiovisual, Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936934/Photos/Instagram%20B_W/1_tcurqn.jpg",
    description:
      "Daniel Door studied media art with Res Ingold at the AdBK Munich. He composes and performs experimental electronic music in collaboration with and for Anton Kaun RUMPELN, Scha-moni Musik, Raketenbasis Haberlandstrasse, TAMTAM-Kollektiv, Anuk Jovović, Stefan Römer and others. In his artistic practice he explores noise and glitches in the relationships between man, machine and environment.",
    link: "https://soundcloud.com/danieldoor",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Diogo Da Cruz",
    identification: "Visual Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627937008/Photos/Instagram%20B_W/10_wznor6.jpg",
    description:
      "Diogo da Cruz is a conceptual artist from Lisbon and lives in Munich. He received a BA in Sculpture-rei at FBAUL (2012) and a Diploma in Sculpture at ADBK Munich (2016), where he studied with Hermann Pitz, Ceal Floyer, Andrea Fraser and Tyler Coburn. His practice relies on the use of technology to replicate or imitate ancient structures of Western society. His weaves together scientific research, historical elements, memory and fiction. He is the initiator and director of the research group SFB42, which will be part of SVS Collisions 2021.",
    link: "https://diogocruz.net/",
    editions: ["canfranc"],
  },
  {
    name: "David Goldberg",
    identification: "Audiovisual and Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627937002/Photos/Instagram%20B_W/11_tvkvcv.jpg",
    description:
      "David Goldberg studied sculpture at the AdBK Munich in the class of Olaf Nicolai. Since 2019 he has been studying Sound Studies and Sonic Arts at the UdK Berlin. In 2016 he was part of the two-week SVS Creative Lab at the Spatial Sound Institute Budapest. This resulted in installations and concerts in Germany and Europe. Performances as an electronic musician have taken him to Berghain and the Blitz Music Club in Munich, among other places.",
    link: "http://www.davidgoldberg.net",
    editions: ["canfranc", "muc_festival", "muc_exhibition"],
  },
  {
    name: "Jens L. Thomsen",
    identification: "Sound Artist, Music Producer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,g_face:auto,h_96,w_96/v1627936946/Photos/Jens_L._Thomsen_st69e7.jpg",
    description:
      "Jens L. Thomsen is an artist and musician from the Faroe Islands. His sound installation NORÐ was commissioned by the Southbank Centre and exhibited at Royal Festival Hall in London, also Føroyar 5.2 which with the use of seismic technology allowed him to play on mountains as if they were instruments. His main artistic outlet ORKA has twice been nominated for the Nordic Council Music Award. Jens is credited on close to a hundred releases as well as numerous film, art, theatre and dance pieces.",
    link: "https://jenslthomsen.com/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Dirk Handreke",
    identification: "Audiovisual and Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936990/Photos/Instagram%20B_W/9_q1muze.jpg",
    description:
      "Dirk Handreke works as an audio interaction designer and lives in Stuttgart, Germany. He is currently doing a master's degree in music informatics at the Hochschule für Musik in Karlsruhe. While focusing on music software development, his current artistic projects explore the possibilities of musical interaction with rhythmic shape-shifting, metric modulation and micro-rhythmic patterns. His works are part of film and game productions, theatre performances and museum exhibitions.",
    link: "https://cargocollective.com/wellenwucht/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Mika Bankomat",
    identification: "Audiovisual and Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,e_grayscale,w_96/v1627936947/Photos/avatars-UecK1AqzBR97kvqM-FmpYOw-t240x240_gtpnku.jpg",
    description:
      "Michaela Andrae aka Mika Bankomat studied at the Academy of Fine Arts with Prof. Res Ingold. Her exploration of wearable sound interfaces led to the Master's programme Fashion & Technology at the Kunstuniversität in Linz in 2020. The conceptual discussion of socio-political structures is transferred to concrete places, materials and physical phenomena. In audiovisual, performative and sculptural translations, moments of shear, irritation, touch, rupture and collapse of systems are investigated.",
    link: "https://contemporarydairy.bandcamp.com/track/leisersiff-auf-dem-teppich",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Specimens",
    identification: "Sound Artist, Producer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627937000/Photos/Instagram%20B_W/13_n62oxl.jpg",
    description:
      "Alex Ives alias Specimens explores the avenues of self awareness & racial identity through the medium of ambient drones and experimental frequencies. The London based artist has toured across Europe, Japan & America performing alongside Machinefabriek, Midori Hirano, Chihei Hatakeyama, John Bence & more. Alex has released 2 full length LPs - of which ‘In The Dust Of Idols’ (SVS Records & his very own First Terrace) featured as one of FACT Magazines “Ambient Albums Of The Year”",
    link: "https://www.instagram.com/_specimens_",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Joe Summers",
    identification: "Sound Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,e_grayscale,h_96,w_96/v1627936895/Photos/joe_sum_crayty.jpg",
    description:
      "Joe Summers is an artist interested in magnifying & modulating experiences of sound through extramusical interventions, mediation & theatrics. Past & ongoing projects include a live score to Derek Jarman’s ‘The Last of England’, an improvisational competitive duet (where the paradigm of collaboration is replaced with collision), and the construction of a series of acoustic instruments. He co-founded the label First Terrace Records and hosts a monthly radio show on Resonance Extra.",
    link: "https://www.firstterracerecords.com/joe-summers",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Zoe McPherson",
    identification: "Sound Artist, Musicianimages",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936907/Photos/Instagram%20B_W/2_lpjza2.jpg",
    description:
      'Zoë Mc Pherson is a multimedia artist, producer, sound designer, performer, DJ, curator and founder of the AV platform/label/community SFX based in Berlin. She bridges the gap between sound art and visual art. She was awarded best electronic artist 2019 at the Belgian "Octaves de la musique". Releases on Morphine records, SFX, SVS Records, BAKK, Oramics & Kashev Tapes, LavaLava records, Hot Desk, among others.',
    link: "https://zoemcpherson.xyz",
    editions: ["canfranc", "muc_festival"],
  },
  {
    name: "Mandy Mozart",
    identification: "Composer, Multimedia Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936932/Photos/Tilman_Porsch%C3%BCtz_yuo2mf.jpg",
    description:
      "Mandy Mozart is working on the intersection between composition, visual arts, performance, user interfaces and programming. Like a fallen angel they find meaning in embodiment and honesty through songs and poems. Experience the mental transformations Mandy Mozart is encountering as a man, as being, as a splintered persona becoming human again and finding refuge in a ritual made of words and sound dealing with the unsolvable complexities they face in our relationships with machines and hyper realities.",
    link: "https://www.mandymozart.com/",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Nico Daleman",
    identification: "Sound Artist, Musicologist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,e_grayscale,h_96,w_96/v1627936954/Photos/IMG_5134_dr47ka.jpg",
    description:
      "Nico Daleman is a sound artist and musicologist based in Berlin. His pieces explore sonic spatial perception, psychoacoustics, dynamic systems and stochastic processes. He explores new modes of performance and perception, deliberately creating soundscapes and feedback loops that blur the boundaries between sound and space, viewer and performer. His practice encompasses sound synthesis, acoustic instruments, moving images, choreography, neuro- & data science and biochemistry.",
    link: "http://www.nicodaleman.com/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Lukas Rehm / LYBES DIMEM",
    identification: "Composer, Sound Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,e_grayscale,w_96/v1627936895/Photos/Lukas_rehm_von_robert_hamacher_exnfrj.jpg",
    description:
      "Sound artist and musician Lukas Rehm's work moves between the fields of new media, installation art, documentary film, experimental fiction and theatre. Rehm studied art history and theatre studies at the LMU Munich and media art, media theory and scenography at the HfG Karlsruhe, followed by residencies at the Jan Van Eyck Academie Maastricht and a research fellowship at the Akademie für Theater und Digitalität Dortmund.",
    link: "https://lukasrehm.net/",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Tatjana Stürmer",
    identification: "Visual Artist, Graphic Designer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936906/Photos/Instagram%20B_W/tatjana_vf14jd.jpg",
    description:
      "Tatjana Stürmer works between graphic design and visual arts. Her practice explores performative aspects of visual language and their influence on immediate social and political experiences. The speculative and the female imaginary become wanderers within multimedia explorations. She studied at the HfG Karlsruhe, received the scholarship of the Studienstiftung des Deutschen Volkes, the Bundespreis für Kunststudierende and is currently working as an artistic assistant for Metahaven in Amsterdam.",
    link: "https://www.instagram.com/_tatjanasturm/",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Daniel Hermann-Collini",
    identification: "Music Producer, Sound Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936989/Photos/Instagram%20B_W/14_x70osu.jpg",
    description:
      "Daniel Hermann-Collini ist Alumni des Popmusik-Studienganges der Goldsmith University, London und Mitbegründer der SVS Residencies und des Labels SVS Records. Er stammt aus München, lebt und arbeitet als freischaffender Musikproduzent, Musiker und Studiobetreiber bei Sintra, Lissabon. Mit dem Duo Camila Fuchs brachte er zahlreiche Tonträger u.a. auf ATP Records heraus und tourt seit 2012 international.",
    link: "https://www.camilafuchs.com/",
    editions: ["canfranc"],
  },
  {
    name: "Aloïs Yang",
    identification: "Media Artist",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627936997/Photos/Instagram%20B_W/12_c7oubn.jpg",
    description:
      'Aloïs Yang is a media artist, performer and experimental musician. He views sound as the raw material for exploring our perception of space and time - both on the level of the outer physical world and our inner "metaphysical consciousness". To overcome the separation of art forms and genres, Yang takes an integrative approach as he uses various artistic methods from interactive installations to speculative design or live audiovisual performances.',
    link: "https://aloisyang.com/",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "Awu",
    identification: "Sound Artist, Game Designer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627937003/Photos/Instagram%20B_W/awu_fldtzx.jpg",
    description:
      "Retired Chinese punk, cyber human, game artist, student of Udk, currently lives in Berlin suburb, but might move to the fancy downtown soon. In a love and hate relationship with Unity. Played lots of rhythm games and was influenced by them, especially Taiko no tatsujin, which once became my spiritual support. Used to do sound design for a film studio, now working in the video game field.",
    link: "https://awulee.wixsite.com/sounddesign",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Manuela Illera",
    identification: "Media Artist, Musician",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_scale,w_96/v1627937012/Photos/Instagram%20B_W/4_nfqdqy.jpg",
    description:
      "Manuela Illera is an interdisciplinary artist mainly involved with time-based media and constellations related to performing arts. Her feminist political practice, enunciated from the perspective of migration, situates her in the search for radical communications on decoloniality, the body and subversive identities. Her work examines the role of whiteness, capitalism and patriarchal social conformity in culture. Manuela Illera is currently studying Time-based media with Prof. Julian Rosefeldt at the Academy of Fine Arts in Munich.",
    link: "https://www.manuelaillera.com",
    editions: ["canfranc", "muc_exhibition"],
  },
  {
    name: "Rosa Luckow",
    identification: "Media Artist, DJ",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,e_grayscale,h_96,w_96/v1627936896/Photos/Rosa_Luckow_dvgpwy.jpg",
    description:
      "Rosa Luckow is studying at the Academy of Fine Arts Munich in Klasse Rosefeldt. Her artistic practice involves moving image, sound and sculpture often embedded in multimedia installations and performances, emphasizing on site specific and material properties, researching indeterminacy and imperfection and dealing with sociocultural themes. Code and modular based live performances involving field recorded, sampled loops extend her artistic practice.",
    link: "https://rosi96.de/",
    editions: ["canfranc", "muc_exhibition", "muc_festival"],
  },
  {
    name: "houaïda",
    identification: "Composer, Performer",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "houaïda is a composer, performer, and scientist. She adopts a trans-disciplinary approach to critically analyze and deconstruct Western and normative perceptions of sound, music, and performance. She holds an MA in astrophysics and is influenced by scientific strategies and embodied practices centered on radical sensuality, collectivity and pluralism. She believes that practicing and learning from both can be a seed to a (Western) revolution that might lead us into a better living and dying with and within the Pluriverse. Her research project Radical Sensuality was supported with a grant award from the Fond Darstellender Künste in 2018/2019. In addition to her solo appearances as houaïda and miss machine and her appearances as part of the Talking Straight collective, she worked for several theater and dance performances, amongst others, at the Haus der Berliner Festspiele, the Maxim Gorki Theater, and Gropiusbau. She is a former member of the Holly Herndon Ensemble and curated the “post-global” performance series at Maxim Gorki's Studio Я, titled “home: a Transonic Journey.” houaïda works internationally and lives in Berlin.",
    link: "https://www.houaida.com/",
    editions: ["canfranc"],
  },
  {
    name: "Bartellow & Michelson",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "In their collaborative work, Beni Brachtel aka Bartellow and Sven Michelson share their passion for Leftfield Techno, EDM and Ambient music. As a duo they are currently performing live in 'Schiffbruch der Fregatte Medusa' at Marstall am Residenztheater in Munich.",
    link: "https://www.bartellow.com/",
    editions: ["muc_festival"],
  },
   {
    name: "Cosmica Bandida",
    identification: "Live Concert",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Cosmica Bandida is a transdisciplinary art collective and band exploring the materiality of sound through installations, objects and performances. With critical lyrics against patriarchy, police abuse and white hypocrisy, the artists' duo combines atmospheric and ambient compositions with modular synths, keyboards, and rhythmical patterns inspired by the Colombian traditional and psychedelic cumbia.",
    link: "https://www.bartellow.com/",
    editions: ["muc_festival"],
  },
   {
    name: "Rosi96",
    identification: "Media Artist, Sound Artist, DJ",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Rosa Luckow aka Rosi96 plays sets ranging from abstract electronics to broken club. She co-runs the Munich-based online community radio Radio 80000, where she hosts her regular monthly show rosi96.de. Rosa has initiated and curated many sound-related projects, e.g. at Museum Brandhorst and Dokumenta 22.",
    link: "https://rosi96.de/",
    editions: ["muc_festival"],
  },
    {
    name: "Heinrich Schwarzer",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Heinrich Schwarzer has been making music using Max/Msp for the past 4 years. In November 2020 he released «Zähler, Figur, Puls 0-2» on Calum Gunn's Conditional Records, which was described as 'some of the most thrilling music around' by boomkat and 'precise yet playful, sharp yet warm' by Gunn. He also initiated the now Manchester based music-label, event-series & radioshow Mutualism with Iceboy Violet in 2015, which has later seen artists Bftt, Clemency and Rob Thornber join.",
    link: "https://con034.conditional.club/",
    editions: ["muc_festival"],
  },
    {
    name: "Ferdinand Domes",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Ferdinand Domes is a munich based music producer and DJ. His current work deals with contemporary impressions from the field of sound processing. The emphasis is on experimental soundscapes, sonic scenes and hypnotic percussion heavy tracks. He has released on tax free records, routes not roots, and under his own imprint 0203983a. His monthly show on radio 80000 focuses on tracks and sound sketches by him and his friends.",
    link: "https://soundcloud.com/frdnnd",
    editions: ["muc_festival"],
  },
    {
    name: "ChaosAngel",
    identification: "DJ Set",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Chaos Angel alias Vanessa Bosch is artist, composer and graduate of HfG Karlsruhe. She moves between the niches of different media, from ephemeral installations and performances to cinematic sound pieces. Central to her latest projects and research is hydrofeminism and water as a queer matter, both in ecological and philosophical contexts. She mainly works with audio-visual techniques, structured through narrative and conceptual methods. She is co-founder of the artist collective and label 76666.",
    link: "https://soundcloud.com/pinkfatbuddha",
    editions: ["muc_festival"],
  },
    {
    name: "Ghosttown",
    identification: "DJ Set",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Ghosttown frames a multi-genre collective, label, eventseries and platform that emerged over the past decade from Munich's non-commercial underground. The five members Kelburrt, Glen, Sangeet, Rompa and Brootworth are active as DJs, producers, promoters and radio hosts aiming to explore the hybrid sounds between deep diving bass experiences and highly extatic rave rhythms. With its recent releases, the record label represents a dark and atmospheric, but highly dancefloor oriented culture.",
    link: "https://soundcloud.com/ghsttwn",
    editions: ["muc_festival"],
  },
    {
    name: "Elsa M'Bala",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "“... Elsa M'Bala addresses the protests by the English-speaking part of Cameroon against their marginalization by predominantly Francophone structural systems in place: French colonization’s linguistic and political legacy. [...] By using technology as an empowering tool and through her keen observations, she explores further the interconnections between race, gender, technology, and spirituality. Specifically in times of acute racial crisis in Europe and in the US, while the dominant discourse reproduces the patterns of disconnected narratives, exclusion, and invisibility.” (written by Aicha Kaleko for the Berlin biennale 10)",
    link: "https://elsambala.com/",
    editions: ["muc_festival"],
  },
    {
    name: "Jessica Ekomane",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Jessica Ekomane is an electronic musician and sound artist. Her practice unfolds around live performances and installations. Her quadrophonic performances, characterized by their physical affect, seek a cathartic effect through the interplay of psychoacoustics, the perception of rhythmic structures and the interchange of noise and melody. Her ever-changing and immersive sonic landscapes are grounded in questions such as the relationship between individual perception and collective dynamics or the investigation of listening expectations and their societal roots.",
    link: "https://www.jessicaekomane.com/",
    editions: ["muc_festival"],
  },
    {
    name: "Lovefingers",
    identification: "DJ Set",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Andrew Hogge alias Lovefingers is a DJ, producer, multi-instrumentalist, and the ear and creative of his record label ESP Institute. The L.A. native has released widely sought after records from a range of esoteric producers all while maintaining his trademark future-forward sound and aesthetic. Lovefingers has the luxury of being able to cherry pick his gigs at the world's best club nights, warehouse parties, festivals, collaborate with his friends, as well as make regular appearances on cult radio shows like Beats In Space and his own monthly show on Dublab.",
    link: "https://www.instagram.com/lovefingers/",
    editions: ["muc_festival"],
  },
    {
    name: "Ojoo Gyal",
    identification: "DJ Set",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Born and raised in Morocco, OJOO GYAL, who recently relocated to Brussels, has a versatile approach to DJing, as evidenced by her residencies at Noods and Movement Radio. Her mixes range from old school dancehall to its contemporary mutations, heavy dub, bass, breaks, baile funk and other radical twists of electronic music and alternative styles of club sound.",
    link: "https://soundcloud.com/ogyal",
    editions: ["muc_festival"],
  },
    {
    name: "Konrad Wehrmeister",
    identification: "DJ Set",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "From deftly saturated dub to intimate studies of rhythmic noise Konrad Wehrmeister has carved out a distinctive niche in the electronic music landscape. With contrasting releases, the artist has created a solid platform for future experimentation.",
    link: "https://soundcloud.com/konrad-wehrmeister",
    editions: ["muc_festival"],
  },
    {
    name: "Kamron Saniee",
    identification: "Liveset",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Kamron Saniee draws inspiration from various musical cultures. Influences from electronica, IDM, neoclassical music, Middle Eastern synthesizer music, and video game soundtracks dominate his compositions. He has performed at venues including Elsewhere Brooklyn, Future Space and Fridman Gallery, and at Budapest's spatial sound institute 4DSOUND and the Performing Arts Meeting in Yokohama with SVS. His Euphoric Studies EP was released on SVS Records in 2021. Kamron Saniee lives and works in New York City.",
    link: "https://soundcloud.com/konrad-wehrmeister",
    editions: ["muc_festival"],
  },
    {
    name: "Anonima/Luci - Katatonic Silentio",
    identification: "Installation",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "Anonima Luci is a light art project initiated by Stefania Kalogeropoulos and Alberto Saggia. Together with Milan-based sound artist Katatonic Silentio, they do research and develop experimental installations. It is a collaboration born from a shared vision for sensuality and total abandon to performance. Space and architecture become completely reconfigured by light and illuminated using laser beams. Sound plays an essential role in this process by further highlighting the space and creating a dynamic system that is constantly evolving and interacting.",
    link: "https://soundcloud.com/konrad-wehrmeister",
    editions: ["muc_festival"],
  },
    {
    name: "100202 2023.282.23",
    identification: "29237 2.232.20",
    image:
      "https://res.cloudinary.com/svscollisions/image/upload/c_fill,h_96,w_96/v1627936930/Photos/HOUAIDA_INITIALFO%CC%88RDERUNG_FOTO_fbwmrc.jpg",
    description:
      "2388 .2.2202.3.3826102 shrimp",
    link: "/",
    editions: ["muc_festival"],
  },
];
