import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/2022/SectionExhibitionArtistsBackground.png";
import Section from "../../Section/Section";
import ArtistDetailList from "../2021/ArtistDetailList";
const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: normal;
  text-align: center;
  color: var(--festival-primary);
  > section:first-of-type {
    background-color: #000;
    padding: 6rem 0 5rem 0;
  }
  .artist-detail-list > section {
    gap: 0;
    > div {
      background-color: #000;
      padding: 1rem;
    }
  } 
  @media ${device.mdDown} {
    font-size: 2rem;
    line-height: 2rem;
  }
  h2 {
    font-size: 4rem;
    line-height: 4rem;
    margin: 3rem 0;
    display: inline;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    color: var(--festival-primary);
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }

`;

const ExhibitionArtists = () => {
  return (
    <Container>
      <Section>
        <h2>EXHIBITION ARTISTS</h2>
      </Section>
      <ArtistDetailList edition="muc_exhibition" />
    </Container>
  );
};

export default ExhibitionArtists;
