import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/2022/SectionTimetableBackground.png";
import mountrushmore from "../../../images/2022/SectionTimetableMountRushmore.svg";
import Section from "../../Section/Section";

const Container = styled.div`
  position: relative;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 3rem;
  line-height: 3rem;
  font-weight: normal;
  color: var(--festival-primary);
  padding: 2rem 0;
  img {
    width: 100%;
  }
  @media ${device.mdDown} {
    font-size: 2rem;
    line-height: 2rem;
  }
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    margin: 3rem 0;
    text-align: center;
    font-weight: normal;
    text-transform: uppercase;
    color: var(--festival-primary);
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  section {
    h3 {
      margin: 3rem 0 0 0;
      font-size: 3rem;
      line-height: 3rem;
      font-weight: bold;
      color: var(--festival-primary);
      @media ${device.mdDown} {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
    h2 {
      /* position: sticky; */
      margin: 3rem 0;
      font-size: 3rem;
      line-height: 3rem;
      top: 2rem;
      color: var(--festival-primary);
      @media ${device.mdDown} {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
`;

const TimetableList = styled.div`
  text-align: left;
  width: 100%;
`;
const TimetableItem = styled.div`
  font-size: 2rem;
  line-height: 2rem;
  display: grid;
  grid-template-columns: 5rem 21rem auto;
  &:last-of-type {
    border-bottom: 2px solid var(--festival-primary);
  }
  > div {
    padding: 0.5rem;
    border-left: 2px solid var(--festival-primary);
    border-top: 2px solid var(--festival-primary);
    color: var(--festival-primary);
    &:last-of-type {
      border-right: 2px solid var(--festival-primary);
    }
  }
  @media ${device.mdDown} {
    display: block;
  }
`;

const DayItem = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--festival-primary);
  &&& {
    color: var(--festival-secondary);
  }
  font-size: 2rem;
  line-height: 2rem;
  font-weight: bold;
  padding: 0.5rem;
  border-top: 2px solid var(--festival-primary);
  border-left: 2px solid var(--festival-primary);
`;
const Hours = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;
const Location = styled.div``;
const Lineup = styled.div``;
const Time = styled.strong`
  font-size: 1rem;
  &::after {
    content: " ";
  }
`;
const Act = styled.span`
  &::after {
    content: " ";
  }
`;
const Event = styled.span``;

const Timetable = () => {
  return (
    <Container>
      <Link to="/timetable">
        <h1>LINEUP</h1>
        </Link>
      <Section>
        <TimetableList>
          {/* <Menu>
            <MenuItem isActive={activeDay === 19} href="#19">
              19
            </MenuItem>
            <MenuItem isActive={activeDay === 20} href="#20">
              20
            </MenuItem>
            <MenuItem isActive={activeDay === 21} href="#21">
              21
            </MenuItem>
            <MenuItem isActive={activeDay === 23} href="#23">
              23/24
            </MenuItem>
          </Menu> */}

          <DayItem id="19">19.7</DayItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 1: MONOM</Location>
            <Lineup>
              <Event>
                <Time>16</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
              <Event>
                <Time>19</Time>
                <Act>Live-Performance: Awu</Act>
              </Event>
              <Event>
                <Time>20</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>              
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 2: Installations</Location>
            <Lineup>
              <Event>
                <Time>16</Time>
                <Act>Exhibition</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>20—3</Hours>
            <Location>Ampere</Location>
            <Lineup>
              <Event>
                <Time>20h</Time>
                <Act>Rosa Luckow (Video und sound performance)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>20h45</Time>
                <Act>Heinrich Schwarzer (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>21h30</Time>
                <Act>Ferdinand Domes (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>22h30</Time>
                <Act>ChaosAngel (DJ-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>23h30</Time>
                <Act>Daniel Door (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>0h</Time>
                <Act>Zoe McPherson (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>1h</Time>
                <Act>Ghosttown (DJ-Set)</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—18</Hours>
            <Location>Radio 80000</Location>
            <Lineup>
              <Event>
                <Act>LIVE aus dem Muffatwerk</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>18—20</Hours>
            <Location>Muffatcafé</Location>
            <Lineup>
              <Event>
                <Act>
                  <a href="https://www.muffatwerk.de/de/events/view/5981/cafe-kosmos" rel="noreferrer" target="_blank">"Café &amp; Kosmos" — Gesprächsreihe des Max-Plack-Instituts</a>
                </Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <td className="empty"></td>
          <DayItem id="20">20.7</DayItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 1: MONOM</Location>
            <Lineup>
                <Event>
                <Time>16</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
              <Event>
                <Time>19</Time>
                <Act>Live-Performance: Mandy Mozart</Act>
              </Event>
              <Event>
                <Time>20</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 2: Installations</Location>
            <Lineup>
              <Event>
              <Time>16</Time>
                <Act>Exhibition</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>20—3</Hours>
            <Location>Ampere</Location>
            <Lineup>
              <Event>
                &nbsp;<Time>20h</Time>
                <Act>Specimens (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>20h45</Time>
               <Act>Lybes Dimem (AV Live-Set)</Act>
              </Event>
                <Event>
                &mdash;&nbsp;<Time>21h30</Time>
                <Act>Elsa M&apos;Bala (Live-Set)</Act>
              </Event>
             <Event>
                &mdash;&nbsp;<Time>22h30</Time>
                <Act>Xïola Yin (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>23h30</Time>
                <Act>Kamron Saniee (Live-Set)</Act>
              </Event>
              <Event>
                 &mdash;&nbsp;<Time>0h</Time>
                <Act>Jessica Ekomane (Live-Set)</Act>
              </Event>
              <Event>
                &mdash;&nbsp;<Time>0h30</Time>
                <Act>Lovefingers (DJ-Set)</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—18</Hours>
            <Location>Radio 80000</Location>
            <Lineup>
              <Event>
                <Act>LIVE aus dem Muffatwerk</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <DayItem id="21">21.7</DayItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 1: MONOM</Location>
            <Lineup>
              <Event>
                <Time>16</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
              <Event>
                <Time>19</Time>
                <Act>Live-Performance: Alois Yang</Act>
              </Event>
              <Event>
                <Time>20</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 2: Installations</Location>
            <Lineup>
              <Event>
              <Time>16</Time>
                <Act>Exhibition</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>20—5</Hours>
            <Location>Muffatcafé</Location>
            <Lineup>
              <Event>
                <Act>Cosmica Bandida (concert)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>Mandy Mozart (Live-set)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>Bartellow &amp; Sven Michelson (Live-set)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>Slovva (DJ-Set)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>Ojoo Gyal (DJ-Set)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>rosi96 B2B David Goldberg (DJ-Set)</Act>
              </Event>
              <Event>
                <Time></Time>&mdash;&nbsp;
                <Act>Konrad Wehrmeister (DJ-Set)</Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—20</Hours>
            <Location>Radio 80000</Location>
            <Lineup>
              <Event>
                <Act>LIVE aus dem Muffatwerk</Act>
              </Event>
            </Lineup>
          </TimetableItem>

          <DayItem>22.7 Closed</DayItem>

          <DayItem id="23">23.7</DayItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 1: MONOM</Location>
            <Lineup>
              <Event>
                <Time>16</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
              <Event>
                <Time>19</Time>
                <Act>Live-Performance: Alois Yang and guests</Act>
              </Event>
              <Event>
                <Time>21</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>16—23</Hours>
            <Location>Studio 2: Installations</Location>
            <Lineup>
              <Event>
              <Time>16</Time>
                <Act>Exhibition</Act>
              </Event>
            </Lineup>
          </TimetableItem>
                    <DayItem id="24">24.7</DayItem>
          <TimetableItem>
            <Hours>14—18</Hours>
            <Location>Studio 1: Monom</Location>
            <Lineup>
              <Event>
                <Time>16</Time>
                <Act>Sound Works: <a href="https://docs.google.com/spreadsheets/d/1WVuwYhCKtTKEHvwlPolwLO6iIed3Qq3ZCKKXTbHfMmg/edit#gid=0"  rel="noreferrer" target="_blank"> SEE TIMETABLE</a></Act>
              </Event>
            </Lineup>
          </TimetableItem>
          <TimetableItem>
            <Hours>14—18</Hours>
            <Location>Studio 2: Installations</Location>
            <Lineup>
              <Event>
              <Time>14</Time>
                <Act>Exhibition</Act>
              </Event>
            </Lineup>
          </TimetableItem>
        </TimetableList>
      </Section>
      <img
        src={mountrushmore}
        className="mountrushmore"
        alt="mounta rushmore"
      />
    </Container>
  );
};

export default Timetable;
