import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/BackgroundExplosion.png";
import Section from "../../Section/Section";
import massive from "./../../../images/massive.png";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
  section {
    img {
      width: 100%;
    }
  }
`;

const Explosion = () => {
  return (
    <Container>
      <Section>
        <img src={massive} alt="blackhole" />
      </Section>
    </Container>
  );
};

export default Explosion;
