import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";

const Container = styled(Link)`
&&& {
  cursor: pointer;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.2rem;
  border-radius: 4rem;
  cursor: pointer;
  background-color: var(--festival-primary);
  color: var(--festival-secondary);
  padding: 1.5rem;
}
`;

const HomepageLink = () => {
  return <Container to={"/"}>⇜ BACK</Container>;
};
export default HomepageLink;
