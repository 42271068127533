import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/2022/SectionOutroBackground.png";
import spring from "../../../images/2022/SectionOutroSpring.svg";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    margin-top: -25rem;
    width:100%;
    animation: stretching 10s infinite;
  }
  @keyframes stretching {
    0% { transform: scale(1) translateY(0); }
  50% { transform: scale(0.9) translateY(25rem); }
  100% { transform: scale(1) translateY(0);; }
}

`;

const Outro = () => {
  return (
    <Container>
      <img src={spring} alt="spring"/>
    </Container>
  );
};

export default Outro;
