import styled from "@emotion/styled";
import React from "react";
import Tilt from "react-parallax-tilt";

const Container = styled.div`
  position: fixed;
  bottom: 2rem;
  font-size: 3rem;
  line-height: 3rem;
  letter-spacing: -0.2rem;
  right: 2rem;
  z-index: 1000000;
  cursor: pointer;
  div {
    border-radius: 4rem;
    overflow: hidden;
  }
  a {
    cursor: pointer;
    background-color: var(--festival-primary);
    color: var(--festival-secondary);
    padding: 1.5rem;
    display: block;
  }
`;

const Tickets = () => {
  return (
    <Container>
      <Tilt
        className="tilt-img"
        glareEnable={true}
        glareMaxOpacity={0.8}
        glareColor="black"
        glarePosition="all"
        glareBorderRadius="0"
        tiltMaxAngleX={35}
        tiltMaxAngleY={35}
        perspective={1600}
        scale={1.1}
        transitionSpeed={2000}
        gyroscope={true}
      >
        <a
          href="https://ra.co/events/1547351"
          target="_blank"
          rel="noreferrer"
        >
          TICKETS ➝
        </a>
      </Tilt>
    </Container>
  );
};
export default Tickets;
