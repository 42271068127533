import styled from "@emotion/styled";
import React from "react";
import handleViewport from "react-in-viewport";
import Credits from "./Credits";
import Description from "./Description";
import ExhibitionArtists from "./ExhibitionArtists";
import FestivalArtists from "./FestivalArtists";
import Interlude from "./Interlude";
import Introduction from "./Introduction";
import Locations from "./Locations";
import Outro from "./Outro";
import Timetable from "./Timetable";

const Block = (props) => {
  const { forwardedRef, children } = props;
  return (
    <div className="viewport-block" ref={forwardedRef}>
      {children}
    </div>
  );
};

const ViewportBlock = handleViewport(Block /** options: {}, config: {} **/);

const Container = styled.div`
  background-color: transparent;
  color: var(--festival-primary);
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  b,
  div {
    color: var(--festival-primary);
  }
`;

const Festival = () => {
  return (
    <Container>
      <ViewportBlock
        onEnterViewport={() => (window.festivalVisibility = true)}
        onLeaveViewport={() => (window.festivalVisibility = false)}
      >
        <Introduction />
        <Description />
        <Interlude />
        <Timetable />
        <ExhibitionArtists />
        <FestivalArtists />
        <Locations />
        <Credits />
        <Outro />
      </ViewportBlock>
    </Container>
  );
};

export default Festival;
