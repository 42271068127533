import styled from "@emotion/styled";
import React from "react";
import backgroundImage from "../../../images/BackgroundDescription.png";
import Section from "./../../Section/Section";

const Container = styled.div`
  padding-top: 20rem;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; 
  section {
    > p {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
`;

const Description = () => {
  return (
    <Container>
      <Section>
        <p>
          Being in two different places at the same time – that sounds more like
          a pipe dream than reality. And yet scientists have been working on
          precisely this phenomenon for decades: What is our reality actually
          like if something can be there and not there at the same time? Is this
          barely perceptible form of matter even behind one of the greatest
          mysteries of cosmology, namely the invisible dark matter that is
          nevertheless necessary for the existence of the universe?
        </p>
        <p>
          Particle physics and music use a common vocabulary: Waves,
          Frequencies, Noice, noisecancelling, Transientfiltering etc. – The SVS
          Collective starts the dialogue with sciuentists from TU Munich and
          Laboratorio subterráneo de Canfranc to express common subjects through
          a common language: Is music subjected to chronology? Can temporal
          stringency be overcome? Can sound be experienced differently? How do
          particles sound, wenn they collide?
        </p>
        <p>
          Together with the media artist Daniel Door, composer Benedikt Brachtel
          and sound artist Daniel Hermann-Collini launched the series SVS
          Collisions, to expand the respective artistic focus.
        </p>

        <p>
          This year’s meeting place: The former Art Nouveau railway station of
          Canfranc.
        </p>

        <p>
          From 24 July to 8 August 2021 International mixed media artists will
          work there together with physicists at the Laboratorio subterraneo de
          Canfranc – one of the leading research laboratories on dark matter –
          to create an interdisciplinary field of experimentation: In mutual
          exchange, art forms are to be created that connect both worlds – from
          multimedia art to visual art to live performances that act as dialogue
          partners and mediators of science.
        </p>
 
        <p>
          The results of this encounter will be presented in Munich later this
          year.
        </p>
      </Section>
    </Container>
  );
};

export default Description;
