import useInterval from "./Hooks/useInterval";
import { useLayoutStore } from "./Stores/LayoutStore";
import { shuffle } from "./Utilities/utils";

// TODO: useQuery to get data from external cms here
const LayoutLogic = () => {
  const artists = useLayoutStore((store) => store.artists);
  const setArtists = useLayoutStore((store) => store.setArtists);
  const isPlaying = useLayoutStore((store) => store.isPlaying);
  const delay = useLayoutStore((store) => store.delay);

  useInterval(
    () => {
      setArtists(shuffle([...artists]));
    },
    isPlaying ? delay : null
  );
  return <></>;
};
export default LayoutLogic;
