import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/BackgroundSupporters.png";
import logoGoethe from "./../../../images/logo-goethe.png";
import logoKulturreferat from "./../../../images/logo-kulturreferat.png";
import logoLsc from "./../../../images/logo-lsc.png";
import logoRadio80k from "./../../../images/logo-radio80k.png";
import logoSvs from "./../../../images/logo-svs.png";
import logoTum from "./../../../images/logo-tum.png";

const Container = styled.section`
  padding-top: 4rem;
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 6rem;

  > div {
    margin: 0 auto;
    width: var(--inner-width-narrow);
    text-align: center;
    @media ${device.mdDown} {
      width: calc(100% - 2rem);
      padding: 0 1rem;
    }
    &:nth-of-type(1) {
      
    }
    &:nth-of-type(2) {
      margin-top: 4rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1rem;
      @media ${device.mdDown} {
        grid-template-columns: 1fr 1fr;
      }
      @media ${device.xsDown} {
        grid-template-columns: 1fr;
      }
    }
    &:nth-of-type(3) {

    }
  }
`;

const Supporters = () => {
  return (
    <Container>
      <div>
        <p>
          graphic design
          <br />
          Tatjana Stürmer and Lena Thomaka
        </p>
        <p>
          project management / press &amp; public relations
          <br />
          Kulturbuero_to{" "}
        </p>
        <p>
          supported by <br />
          Goetheinstitut and the Department of Arts and Culture Muenchen
        </p>
      </div>
      <div>
        <div>
          <img src={logoGoethe} alt="logo" />
        </div>
        <div>
          <img src={logoLsc} alt="logo" />
        </div>
        <div>
          <img src={logoKulturreferat} alt="logo" />
        </div>
        <div>
          <img src={logoRadio80k} alt="logo" />
        </div>
        <div>
          <img src={logoTum} alt="logo" />
        </div>
        <div>
          <img src={logoSvs} alt="logo" />
        </div>
      </div>
      <div className="impressum">
        <h3>Impressum</h3>

        <p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>
        <p>
          SVS Event &amp; Curation UG (haftungsbeschr&auml;nkt)
          <br />
          Zielstattstr. 37
          <br />
          Rgb
          <br />
          81379 M&uuml;nchen
        </p>

        <p>
          Handelsregister: HRB 256357
          <br />
          Registergericht: Amtsgericht M&uuml;nchen
        </p>

        <p>
          <strong>Vertreten durch:</strong>
          <br />
          Benedikt Brachtel
        </p>

        <p>Kontakt</p>
        <p>
          Telefon: +49 (0) 1778901301
          <br />
          E-Mail: contact@svs-records.com
        </p>

        <p>
          Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
        </p>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
    </Container>
  );
};

export default Supporters;
