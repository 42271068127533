import styled from "@emotion/styled";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { device } from "../../../breakpoints";

const Container = styled.div`
  img {
    width: 100%;
  }
  h3 {
    margin: 0 0 1rem 0;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    @media ${device.mdDown} {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div {
      color: var(--festival-primary) !important;
      font-size: 1rem;
      margin-bottom: 1rem;
    }
    input {
      line-height: 2rem;
      border: 2px solid var(--festival-primary);
      border-radius: 1px;
      font-weight: 600;
      text-transform: uppercase;
      background: var(--festival-secondary);
      color: var(--festival-primary);
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
      width: 16rem;
      &:focus {
        /* border: 2px solid var(--festival-secondary); */
        color: var(--festival-secondary);
        background: var(--festival-primary);
        outline: none;
      }
      &::placeholder {
        color: var(--festival-primary);
      }
    }
    button {
      width: 16rem;
      line-height: 2rem;
      border: 2px solid var(--festival-primary);
      border-radius: 1px;
      background: var(--festival-primary);
      color: var(--festival-secondary);
      font-weight: 600;
      text-transform: uppercase;
      font-size: 1.5rem;
      text-align: center;
      display: block;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover,
      &:focus {
        border: 2px solid var(--festival-primary);
        background: var(--festival-secondary);
        outline: none;
        color: var(--festival-primary);
      }
    }
  }
`;

const url =
  "https://svs-records.us7.list-manage.com/subscribe/post?u=a593eb545bf15f611853675ec&amp;id=a9fd8b81eb";

const Newsletter = () => {
  return (
    <Container>
      <h3>Sign up for Newsletter</h3>
      <MailchimpSubscribe url={url} />
    </Container>
  );
};

export default Newsletter;
