import styled from "@emotion/styled";
import React from "react";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/BackgroundArtistList.png";
import { useLayoutStore } from "../../../Stores/LayoutStore";
import NarrowSection from "../../Section/NarrowSection";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; 
  section {
    h3 {
      margin: 0;
      font-size: 4rem;
      line-height: 4rem;
      font-weight: normal;
      @media ${device.mdDown} {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
`;

const ArtistList = () => {
  const artists = useLayoutStore((store) => store.artists);
  return (
    <Container>
      <NarrowSection>
        {artists.map((artist) => (
          <h3 key={artist.name}>{artist.name}</h3>
        ))}
      </NarrowSection>
    </Container>
  );
};

export default ArtistList;
