import styled from "@emotion/styled";
import React from "react";
import { EditionReference, useLayoutStore } from "../../../Stores/LayoutStore";

const Container = styled.section`
  margin: 0;

`;

type Props = {
  edition: EditionReference;
};

const ArtistList = ({ edition }: Props) => {
  const artists = useLayoutStore((store) => store.artists);
  return (
    <Container className={`artist-detail-list--${edition}`}>
      with{" "}
      {artists
        .filter((artist) => artist.editions.find((e) => e === edition))
        .map((artist) => (
          <span key={artist.name}>{artist.name}, </span>
        ))}
    </Container>
  );
};

export default ArtistList;
