import styled from "@emotion/styled";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { device } from "../../../breakpoints";
import backgroundImage from "../../../images/BackgroundNewsletter.png";
import corona from "../../../images/corona.png";
import NarrowSection from "../../Section/NarrowSection";

const Container = styled.div`
  background-image: url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  section {
    img {
      width: 100%;
    }
    h3 {
      margin: 0 0 1rem 0;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: bold;
      text-transform: uppercase;
      @media ${device.mdDown} {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > div {
        color: var(--primary) !important;
        font-size: 1rem;
        margin-bottom: 1rem;
      }
      input {
        line-height: 2rem;
        border: 2px solid var(--primary);
        border-radius: 1px;
        font-weight: 600;
        text-transform: uppercase;
        background: var(--secondary);
        color: var(--primary);
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
        width: 16rem;
        &:focus {
          /* border: 2px solid var(--secondary); */
          color: var(--secondary);
          background: var(--primary);
          outline: none;
        }
        &::placeholder {
          color: var(--primary);
        }
      }
      button {
        width: 16rem;
        line-height: 2rem;
        border: 2px solid var(--primary);
        border-radius: 1px;
        background: var(--primary);
        color: var(--secondary);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 1.5rem;
        text-align: center;
        display: block;
        cursor: pointer;
        margin-bottom: 0.5rem;
        &:hover,
        &:focus {
          border: 2px solid var(--primary);
          background: var(--secondary);
          outline: none;
          color: var(--primary);
        }
      }
    }
  }
`;

const url =
  "https://svs-records.us7.list-manage.com/subscribe/post?u=a593eb545bf15f611853675ec&amp;id=a9fd8b81eb";

const Newsletter = () => {
  return (
    <Container>
      <NarrowSection>
        <img src={corona} alt="Corona" />
        <h3>Sign up for Newsletter</h3>
        <MailchimpSubscribe url={url} />
      </NarrowSection>
    </Container>
  );
};

export default Newsletter;
